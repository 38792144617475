import React from 'react'
import Global from './styles/global'
import Form from './components/form'
import styled from 'styled-components'
import Grid from './components/grid'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import feirao from './img/feirao.svg'
import { urlAPI } from './components/urlApi'

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const DivHeader = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
`

const Title = styled.h2`
  text-align: center;
  color: #ffffff;
  //margin: 30px;
`

//const url = 'http://192.168.1.99:8800/'
//const url = 'http://localhost:8801/'
const url = urlAPI

function App() {
  const [users, setUsers] = useState([])
  const [onEdit, setOnEdit] = useState(null)

  const getUsers = async () => {
    try {
      const res = await axios.get(url)
      setUsers(res.data.sort((a, b) => (a.recibo > b.recibo ? 1 : -1)))
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [setUsers])

  return (
    <>
      <DivHeader>
        <img
          style={{
            width: '15%'
          }}
          src={feirao}
          alt=""
        />
        <Title>SISTEMA DE RECIBOS</Title>
      </DivHeader>
      <Container>
        <Form onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} />
        <Grid setOnEdit={setOnEdit} users={users} setUsers={setUsers} />
      </Container>
      <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_LEFT} />
      <Global />
    </>
  )
}

export default App
