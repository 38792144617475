import React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { urlAPI } from './urlApi'

const Table = styled.table`
  width: 100%;
  background-color: #ffffff;
  //padding: 20px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
  max-width: 100%;
  margin: 10px auto;
  word-break: break-all;
  color: #192a56;
`

export const Div = styled.div`
  width: 900px;
  border-radius: 5px;
  max-height: 330px;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
    //border: none;
    position: relative;
    border-radius: 3px;
  }
`

export const Thead = styled.thead`
  background-color: #192a56;
  color: #ffffff;
  padding: 5px;
`

export const Tr = styled.tr``

export const Th = styled.th`
  text-align: center;
  border-bottom: inset;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
`

export const Tbody = styled.tbody`
  font-size: 9pt;
`

export const Td1 = styled.td`
  text-align: center;
  padding: 3px;
  color: #34495e;
`

export const Td2 = styled.td`
  padding: 3px;
  color: #34495e;
`
export const Td3 = styled.td`
  padding: 3px;
  text-align: right;
  font-weight: bold;
  color: #34495e;
`

export const body = styled.div`
  height: 100px;
`

export const Button = styled.button`
  padding: 5px;
  color: #34495e;
  background-color: transparent;
  border: transparent;
  cursor: pointer;
`
//const url = 'http://192.168.1.99:8800/'
//const url = 'http://localhost:8800/'
const url = urlAPI

const Grid = ({ users, setUsers, setOnEdit }) => {
  const handleEdit = item => {
    setOnEdit(item)
  }

  const handleDelete = async id => {
    await axios.delete(url + id).then(({ data }) => {
      const newArray = users.filter(user => user.recibono !== id)

      setUsers(newArray)
      toast.success(data)
    })
  }

  return (
    <Div>
      <Table>
        <Thead>
          <Tr>
            <Th>Nº</Th>
            <Th>Lj</Th>
            <Th>Cód</Th>
            <Th>Nome</Th>
            <Th>Valor R$</Th>
            <Th>Referente</Th>
            <Th>Data</Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((item, i) => (
            <Tr Key={i}>
              <Td1 width="7%">{item.recibono}</Td1>
              <Td1 width="3%">{item.loja}</Td1>
              <Td1 width="6%">{item.codigo}</Td1>
              <Td2 width="33%">{item.nome}</Td2>
              <Td3 width="10%">{item.valor}</Td3>
              <Td2 width="26%">{item.referente}</Td2>
              <Td1 width="9%">{item.data}</Td1>
              <Button alignCenter width="3%">
                <FaEdit
                  onClick={() => handleEdit(item)}
                  color="blue"
                  size={15}
                />
              </Button>
              <Button alignCenter width="3%">
                <FaTrash
                  onClick={() => handleDelete(item.recibono)}
                  color="#e84118"
                  size={15}
                />
              </Button>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Div>
  )
}

export default Grid
