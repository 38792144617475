import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { toast } from 'react-toastify'
import { urlAPI } from './urlApi'

const FormContainer = styled.form`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 10px;
`
const inputArea = styled.div`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  width: 170px;
  height: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
`
const Input2 = styled.input`
  width: 600px;
  height: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
`

const Label = styled.label`
  margin: 10px;
  color: #192a56;
`

const Button = styled.button`
  height: 42px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: #ffffff;
`
//const url = 'http://192.168.1.99:8800/'
//const url = 'http://localhost:8800/'
const url = urlAPI

const Form = ({ getUsers, onEdit, setOnEdit }) => {
  const ref = useRef()

  useEffect(() => {
    if (onEdit) {
      const user = ref.current

      user.loja.value = onEdit.loja
      user.valor.value = onEdit.valor
      user.codigo.value = onEdit.codigo
      user.referente.value = onEdit.referente
    }
  }, [onEdit])

  const handleSubmit = async e => {
    e.preventDefault()

    const user = ref.current

    if (
      !user.loja.value ||
      !user.valor.value ||
      !user.codigo.value ||
      !user.referente.value
    ) {
      return toast.warn('Preencha todos os campos!')
    }

    if (onEdit) {
      await axios
        .put(url + onEdit.recibono, {
          loja: user.loja.value,
          valor: user.valor.value,
          codigo: user.codigo.value,
          referente: user.referente.value
        })
        .then(data => toast.success(data))
        .catch(data => toast.error(data))

      toast.success('Recibo editado com sucesso!')
      //toast.success('data')
    } else {
      await axios
        .post(url, {
          loja: user.loja.value,
          valor: user.valor.value,
          codigo: user.codigo.value,
          referente: user.referente.value
        })
        .then(data => toast.success(data))
        .catch(data => toast.error(data))

      toast.success('Recibo salvo com sucesso!')
    }

    user.loja.value = ''
    user.valor.value = ''
    user.codigo.value = ''
    user.referente.value = ''

    setOnEdit(null)
    getUsers()
  }

  return (
    <FormContainer ref={ref} onSubmit={handleSubmit}>
      <inputArea>
        <Label>Loja</Label>
        <Input name="loja" />
      </inputArea>
      <inputArea>
        <Label>Valor</Label>
        <Input name="valor" />
      </inputArea>
      <inputArea>
        <Label>Código</Label>
        <Input name="codigo" />
      </inputArea>
      <inputArea>
        <Label>Referente</Label>
        <Input2 name="referente" />
      </inputArea>

      <Button type="submit">Salvar</Button>
    </FormContainer>
  )
}

export default Form
